.Home.Desktop {
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

/********/

.Home.Desktop #backgroundVideo {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

/********/

.Home.Desktop .MainContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
}

.Home.Desktop .MainContainer .TopRow {
    width: 100%;
    height: 20%;
    min-height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}

.Home.Desktop .MainContainer .TopRow .MintButtonContainer {
    width: 50%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .MainContainer .TopRow .MintButtonContainer img {
    width: auto;
    height: 100%;
    min-height: 100%;
    object-fit: contain;
}
.Home.Desktop .MainContainer .TopRow .MintButtonContainer img:hover {
    cursor: pointer;
}

.Home.Desktop .MainContainer .TopRow .SocialButton {
    width: 25%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .MainContainer .TopRow .SocialButton a {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.Home.Desktop .MainContainer .TopRow .SocialButton a img {
    width: auto;
    height: 100%;
    min-height: 100%;
    object-fit: contain;
}

/********/

.Home.Desktop .MainContainer .ContractSelectRow {
    width: 100%;
    height: 10%;
    min-height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    top: 16%;
    left: 0;
    transition: opacity 2s linear;
    -webkit-transition: opacity 2s linear;
    -moz-transition: opacity 2s linear;
}
.Home.Desktop .MainContainer .ContractSelectRow.Show {
    opacity: 1;
}
.Home.Desktop .MainContainer .ContractSelectRow.Hide {
    opacity: 0;
}

.Home.Desktop .MainContainer .ContractSelectRow .CSRInner {
    width: 75%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .MainContainer .ContractSelectRow .CSRInner .GlowOrb {
    width: 10vh;
    height: 100%;
    min-height: 100%;
}

.Home.Desktop .MainContainer .ContractSelectRow .CSRInner .GlowOrb img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: contain;
}

/********/

.Home.Desktop .MintPopupContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 500ms linear;
    -webkit-transition: opacity 500ms linear;
    -moz-transition: opacity 500ms linear;
}

.Home.Desktop .MintPopupContainer .MPCInner {
    width: min(80vh, calc(100vw - 20px));
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup {
    width: 100%;
    height: min(80vh, calc(100vw - 20px));
    min-height: min(80vh, calc(100vw - 20px));
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .CWPreText {
    width: 80%;
    height: max-content;
    min-height: max-content;
    margin: 0 10%;
    font-family: Ayuthaya, sans-serif;
    font-size: 4vh;
    text-align: center;
    color: white;
    animation: rainbow-text-color 5s linear infinite;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .CWContainer {
    width: 80%;
    height: max-content;
    min-height: max-content;
    margin: 0 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .CWContainer .ConnectWallet {
    width: auto;
    max-width: 100%;
    height: max-content;
    min-height: max-content;
    padding: 6px 20px;
    font-family: Ayuthaya, sans-serif;
    font-size: 24px;
    text-align: center;
    /*background-color: #CC571E;*/
    color: white;
    border-radius: 10px;
    /*box-shadow: 0 0 10px 0 #AAAAAA;*/
    animation: rainbow-box-shadow 6s linear infinite, rainbow-text-color 4s linear infinite;
}
.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .CWContainer .ConnectWallet:hover {
    box-shadow: 0 0 20px 0 white;
    cursor: pointer;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPTitle {
    width: 100%;
    height: 12vh;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #66378C;
    box-shadow: 0 10px 20px 0 #66378C;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPTitle img {
    width: auto;
    height: 100%;
    min-height: 100%;
    object-fit: contain;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner .MPWalletAddress {
    width: 80%;
    height: 24px;
    min-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10%;
    font-family: Ayuthaya, sans-serif;
    font-size: 20px;
    text-align: center;
    color: white;
    text-shadow: 0 0 10px #CC571E;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner .MPDescription {
    width: 80%;
    height: max-content;
    min-height: max-content;
    margin: 2vh 10%;
    font-family: Ayuthaya, sans-serif;
    font-size: 20px;
    text-align: center;
    color: #F843EE;
    text-shadow: 0 0 10px #CC571E;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner .MPMintStatus {
    width: 90%;
    height: max-content;
    min-height: max-content;
    margin: 2vh 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner .MPMintStatus .MPMSInner {
    width: auto;
    height: max-content;
    min-height: max-content;
    padding: 4px 10px 4px 16px;
    font-family: Ayuthaya, sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: black;
    animation: rainbow-bg 5s linear infinite;
    border-radius: 8px;
    cursor: pointer;
}
.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner .MPMintStatus .MPMSInner:hover {
    color: white;
    opacity: 1;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPInner .MPMintCount {
    width: 100%;
    height: max-content;
    min-height: max-content;
    margin-top: 10px;
    font-family: Ayuthaya, sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: white;
}

/********/

.Home.Desktop .StartingOverlay {
    position: absolute;
    transition: opacity 2000ms linear;
    -webkit-transition: opacity 2000ms linear;
    -moz-transition: opacity 2000ms linear;
    overflow: hidden;
}

.Home.Desktop .StartingOverlay .SOVideoContainer {
    transition: width 5000ms linear, height 5000ms linear, min-height 5000ms linear, top 5000ms linear, left 5000ms linear;
    -webkit-transition: width 5000ms linear, height 5000ms linear, min-height 5000ms linear, top 5000ms linear, left 5000ms linear;
    -moz-transition: width 5000ms linear, height 5000ms linear, min-height 5000ms linear, top 5000ms linear, left 5000ms linear;
}

.Home.Desktop .StartingOverlay .SOVideoContainer #startingOverlayVideo {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.Home.Desktop .StartingOverlay .LACContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.Home.Desktop .StartingOverlay .LACContainer .LACCInner {
    width: max-content;
    max-width: 20%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home.Desktop .StartingOverlay .LACContainer .LACCInner .LoadAndContinue {
    width: auto;
    height: 12vw;
    min-height: 12vw;
    font-family: Ayuthaya, sans-serif;
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    line-height: 12vw;
    color: white;
    animation: LACFade 4s infinite ease-in-out;
    text-shadow: 0 0 20px #2925FF;
    user-select: none;
}
.Home.Desktop .StartingOverlay .LACContainer .LACCInner .LoadAndContinue.Wait {
    pointer-events: none;
}
.Home.Desktop .StartingOverlay .LACContainer .LACCInner .LoadAndContinue.Continue {
    pointer-events: auto;
    animation: none;
    text-shadow: 0 0 20px #29A3FF;
    cursor: pointer;
}
.Home.Desktop .StartingOverlay .LACContainer .LACCInner .LoadAndContinue.Continue:hover {
    text-shadow: 0 0 40px #29A3FF;
}

@keyframes LACFade {
    0% { opacity: 0 }
    50% { opacity: 1 }
    100% { opacity: 0 }
}

/********/

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background-color: #66378C;*/
}
.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing.Show {
    z-index: 5;
    opacity: 1;
}
.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing.Hide {
    z-index: -2;
    opacity: 0;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing .MPMPMap {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing .MPMPMap #inProgressVideo {
    width: 100%;
    height: 100%;
    min-height: 100%;
    /*object-fit: contain;*/
    object-fit: cover;
}

.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing .MPMPText {
    width: 30%;
    height: 100%;
    min-height: 100%;
    padding: 0 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Ayuthaya, sans-serif;
    font-size: min(24px, 6vw);
    font-weight: bold;
    /*color: white;*/
    color: #F843EE;
    text-shadow: 0 0 10px #CC571E;
    text-align: center;
}
.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing .MPMPText.Show {
    animation: MPMPTextFade 3000ms infinite ease-in-out;
}
.Home.Desktop .MintPopupContainer .MPCInner .MintPopup .MPMintProcessing .MPMPText.Hide {
    animation: none;
}

@keyframes MPMPTextFade {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

/********/

/*.Home.Desktop .MainContainer .BottomRow {*/
/*    width: 100%;*/
/*    height: 12%;*/
/*    min-height: 12%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*}*/

/*.Home.Desktop .MainContainer .BottomRow .SocialButton {*/
/*    width: 25%;*/
/*    height: 100%;*/
/*    min-height: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: right;*/
/*}*/

/*.Home.Desktop .MainContainer .BottomRow .SocialButton a {*/
/*    width: auto;*/
/*    max-width: 100%;*/
/*    height: auto;*/
/*    max-height: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: right;*/
/*    cursor: default;*/
/*}*/

/*.Home.Desktop .MainContainer .BottomRow .SocialButton a img {*/
/*    width: auto;*/
/*    height: 100%;*/
/*    min-height: 100%;*/
/*    object-fit: contain;*/
/*    cursor: pointer;*/
/*}*/

/********/

.Home.Desktop .MainContainer .RightColumn {
    width: 12vh;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
}

.Home.Desktop .MainContainer .RightColumn .SocialLinks {
    width: 100%;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: column;
}

.Home.Desktop .MainContainer .RightColumn .SocialLinks a {
    width: 100%;
    height: auto;
    min-height: auto;
}

.Home.Desktop .MainContainer .RightColumn .SocialLinks a img {
    width: 100%;
    height: auto;
    min-height: auto;
    object-fit: contain;
    display: block;
}

/********/

.Home.Desktop .EntryTextContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 5s linear;
    -webkit-transition: background-color 5s linear;
    -moz-transition: background-color 5s linear;
}

.Home.Desktop .EntryTextContainer .EntryTextMain {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home.Desktop .EntryTextContainer .EntryTextMain .ETMInner {
    width: 80%;
    height: max-content;
    min-height: max-content;
    margin: 0 10%;
    font-family: Ayuthaya, sans-serif;
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    color: #DDCCFF;
    text-shadow: 0 0 10px #000066;
    user-select: none;
}

.Home.Desktop .EntryTextContainer .EntryTextAfter {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home.Desktop .EntryTextContainer .EntryTextAfter .ETAInner {
    width: 80%;
    height: max-content;
    min-height: max-content;
    margin: 0 10%;
    font-family: Ayuthaya, sans-serif;
    font-size: 6vw;
    font-weight: bold;
    text-align: center;
    color: #DDCCFF;
    text-shadow: 0 0 10px #000066;
    user-select: none;
}

/********/

.Home.Desktop .StartingOverlay .CWContainer {
    width: 80%;
    height: max-content;
    min-height: max-content;
    margin: 0 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Home.Desktop .StartingOverlay .CWContainer .ConnectWallet {
    width: auto;
    max-width: 100%;
    height: max-content;
    min-height: max-content;
    padding: 6px 20px;
    font-family: Ayuthaya, sans-serif;
    font-size: 24px;
    text-align: center;
    /*background-color: #CC571E;*/
    color: white;
    border-radius: 10px;
    /*box-shadow: 0 0 10px 0 #AAAAAA;*/
    animation: rainbow-box-shadow 6s linear infinite, rainbow-text-color 4s linear infinite;
    transition: opacity 1s linear;
}
.Home.Desktop .StartingOverlay .CWContainer .ConnectWallet:hover {
    box-shadow: 0 0 20px 0 white;
    cursor: pointer;
}

/********/

@keyframes rainbow-bg {
    100%,0%{
        background-color: rgb(255,0,0);
    }
    8%{
        background-color: rgb(255,127,0);
    }
    16%{
        background-color: rgb(255,255,0);
    }
    25%{
        background-color: rgb(127,255,0);
    }
    33%{
        background-color: rgb(0,255,0);
    }
    41%{
        background-color: rgb(0,255,127);
    }
    50%{
        background-color: rgb(0,255,255);
    }
    58%{
        background-color: rgb(0,127,255);
    }
    66%{
        background-color: rgb(0,0,255);
    }
    75%{
        background-color: rgb(127,0,255);
    }
    83%{
        background-color: rgb(255,0,255);
    }
    91%{
        background-color: rgb(255,0,127);
    }
}

@keyframes rainbow-text-color {
    100%,0%{
        color: rgb(255,0,0);
    }
    8%{
        color: rgb(255,127,0);
    }
    16%{
        color: rgb(255,255,0);
    }
    25%{
        color: rgb(127,255,0);
    }
    33%{
        color: rgb(0,255,0);
    }
    41%{
        color: rgb(0,255,127);
    }
    50%{
        color: rgb(0,255,255);
    }
    58%{
        color: rgb(0,127,255);
    }
    66%{
        color: rgb(0,0,255);
    }
    75%{
        color: rgb(127,0,255);
    }
    83%{
        color: rgb(255,0,255);
    }
    91%{
        color: rgb(255,0,127);
    }
}

@keyframes rainbow-box-shadow {
    100%,0%{
        box-shadow: 0 0 20px 0 rgb(255,0,0);
    }
    8%{
        box-shadow: 0 0 20px 0 rgb(255,127,0);
    }
    16%{
        box-shadow: 0 0 20px 0 rgb(255,255,0);
    }
    25%{
        box-shadow: 0 0 20px 0 rgb(127,255,0);
    }
    33%{
        box-shadow: 0 0 20px 0 rgb(0,255,0);
    }
    41%{
        box-shadow: 0 0 20px 0 rgb(0,255,127);
    }
    50%{
        box-shadow: 0 0 20px 0 rgb(0,255,255);
    }
    58%{
        box-shadow: 0 0 20px 0 rgb(0,127,255);
    }
    66%{
        box-shadow: 0 0 20px 0 rgb(0,0,255);
    }
    75%{
        box-shadow: 0 0 20px 0 rgb(127,0,255);
    }
    83%{
        box-shadow: 0 0 20px 0 rgb(255,0,255);
    }
    91%{
        box-shadow: 0 0 20px 0 rgb(255,0,127);
    }
}

@keyframes animate-entry-text {
    100%,0%{
        opacity: 0;
    }
    35%,65%{
        opacity: 1;
    }
}

/******************/



















